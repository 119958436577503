






















































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Customer, Entity } from "@/types";
import { customerModule } from "@/store/modules/customers";
import { appModule } from "@/store/modules/app";
import { isValidEmail, isValidRewards } from "@/utils/app-util";

@Component
export default class CustomerForm extends Vue {
  title = "";
  rules = {
    rewards: [() => isValidRewards(this.customer.rewards)],
    email: [() => isValidEmail(this.customer.email)]
  };

  get customer() {
    return customerModule.customer;
  }

  get orders() {
    return customerModule.getOrders();
  }

  get loading() {
    return appModule.loading;
  }

  get mode() {
    return appModule.mode;
  }

  get snackbar() {
    return appModule.snackbar;
  }

  get notice() {
    return appModule.notice;
  }

  save() {
    customerModule.saveCustomer(this.customer);
  }

  cancel() {
    this.$router.push({ name: "customers" });
  }

  closeSnackbar() {
    appModule.closeNotice();
  }

  created() {
    customerModule.getCustomerById(this.$route.params.id);
  }
  
  mounted() {
    if (this.$route.params.id) {
      this.title = "Edit Customer";
      // this.customerAvatar();
    } else this.title = "New Customer";
  }
}
