











































import Table from '@/components/Table.vue';
import TableHeaderButtons from '@/components/TableHeaderButtons.vue';
import SearchPanel from '@/components/SearchPanel.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { debounce } from 'lodash';
import { buildSearchFilters, buildJsonServerQuery, clearSearchFilters } from '@/utils/app-util';
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { productModule } from '@/store/modules/products';
import { appModule } from '@/store/modules/app';

@Component({
  components: {
    Table,
    TableHeaderButtons,
    SearchPanel,
    ConfirmDialog
  }
})
export default class ProductList extends Vue {
  dialog = false;
  dialogTitle = 'Customer Delete Dialog';
  dialogText = 'Do you want to delete this customer?';
  showSearchPanel = false;
  right = true;
  search = '';
  headers = [
    { text: 'Product', left: true, value: 'productName' },
    { text: 'Category', value: 'category.categoryName' },
    { text: 'Price', value: 'unitPrice' },
    { text: 'In Stock', value: 'unitInStock' },
    { text: '', value: 'actions', sortable: false }
  ];
  searchFilter = {
    contain: {
      productName: '',
      category: ''
    },
    greaterThanOrEqual:{
      unitPrice: 0
    },
    lessThanOrEqual:{
      unitPrice: 0
    },
  };
  private productId = '';
  private query = '';
  private snackbarStatus = false;
  private timeout = 2000;
  private color = '';
  private quickSearchFilter = '';
  private itemId = -1;

  print() {
    window.print();
  }
  edit(item) {
    this.$router.push({
      name: 'Product',
      params: { id: item.id }
    });
  }
  add() {
    this.$router.push('NewProduct');
  }
  remove(item) {
    this.itemId = item.id;
    this.dialog = true;
  }

  onConfirm() {
    productModule.deleteProduct(this.itemId);
    this.dialog = false;
  }
  onCancel() {
    this.itemId = -1;
    this.dialog = false;
  }
  searchProducts() {
    this.showSearchPanel = !this.showSearchPanel;
    buildSearchFilters(this.searchFilter);
    this.query = buildJsonServerQuery(this.searchFilter);
    this.quickSearch = '';
    productModule.searchProducts(this.query);
  }

  clearSearchFilters() {
    this.showSearchPanel = !this.showSearchPanel;
    clearSearchFilters(this.searchFilter);
    productModule.getAllProducts();
  }

  reloadData() {
    this.query = '';
    productModule.getAllProducts();
  }

  updateSearchPanel() {
    this.rightDrawer = !this.rightDrawer;
  }

  cancelSearch() {
    this.showSearchPanel = false;
  }

  closeSnackbar() {
    appModule.closeNotice();
  }

  quickSearchCustomers = debounce(function() {
    productModule.quickSearch(this.headers, this.quickSearchFilter);
  }, 500);

  get items() {
    return productModule.items;
  }
  get pagination() {
    return productModule.pagination;
  }
  get loading() {
    return appModule.loading;
  }
  get mode() {
    return appModule.mode;
  }
  get snackbar() {
    return appModule.snackbar;
  }
  get notice() {
    return appModule.notice;
  }

  get rightDrawer() {
    return this.showSearchPanel;
  }

  set rightDrawer(rightDrawer: boolean) {
    this.showSearchPanel = rightDrawer;
  }

  get quickSearch() {
    return this.quickSearchFilter;
  }
  set quickSearch(val) {
    this.quickSearchFilter = val;
    this.quickSearchFilter && this.quickSearchCustomers();
  }

  created() {
    productModule.getAllProducts();
  }
  mounted() {
    this.$nextTick(() => {
      console.log(this.headers);
    });
  }
}
