



















import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class SearchPanel extends Vue {
  @Prop({ required: true }) rightDrawer: boolean;

  get rightDrawerVal() {
    return this.rightDrawer;
  }
  set rightDrawerVal(r: boolean) {
    this.rightDrawer = r;
  }

  public right = true;

  created() {}

  mounted() {
    this.$nextTick(() => {});
  }
}
