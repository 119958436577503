




























































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { userModule } from "@/store/modules/user";

@Component
export default class Login extends Vue {
  private email = "admin@test.com";
  private pass = "password";
  private error = false;
  private text = "";

  public gotoDashboard() {
    this.$router.push("/");
  }

  public async login() {
    await userModule.signIn({ username: this.email, password: this.pass });
    this.$router.push("/");
  }
}
