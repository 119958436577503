

























































































import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { userModule } from '@/store/modules/user';


@Component
export default class App extends Vue {
  get signedIn() {
    return userModule.isSignedIn;
  }

  get user() {
    return userModule.user;
  }

  private dialog = false;
  private mini = false;
  private dialogText = '';
  private dialogTitle = '';
  private isRootComponent = true;
  public drawer = window.innerWidth > 960;
  private fixed = false;
  private items: AppMenu[] = [
    {
      icon: 'mdi-view-dashboard',
      title: 'Dashboard',
      vertical: 'Dashboard',
      link: 'dashboard'
    },
    {
      icon: 'mdi-point-of-sale',
      title: 'Order',
      vertical: 'Order',
      link: 'orders'
    },
    {
      icon: 'mdi-account-group',
      title: 'Customer',
      vertical: 'Customer',
      link: 'customers'
    },
    {
      icon: 'mdi-book-multiple',
      title: 'Product',
      vertical: 'Product',
      link: 'products'
    },
    {
      icon: 'mdi-information-outline',
      title: 'About',
      vertical: 'About',
      link: 'about'
    }
  ];

  private userMenus: AppMenu[]  = [
    {
      icon: 'bubble_chart',
      title: 'Logout',
      link: 'login'
    },
    {
      icon: 'bubble_chart',
      title: 'Change Password',
      link: 'changepassword'
    }
  ];

  private miniVariant = false;
  private right = true;
  private rightDrawer = false;
  private menuItem = '';

  created() {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, _from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress;
        // parse meta tags
        this.$Progress.parseMeta(meta);
      }
      
      //  start the progress bar
      this.$Progress.start();

      //  continue to next page
      next();
    });
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((to, from) => {
      if (to.name !== 'ErrorPage') {
        this.menuItem = to.name;
      }
      //  finish the progress bar
      this.$Progress.finish();
    });
  
  }

  get activeMenuItem() {
    return this.menuItem;
  }

  handleNavigtiion(item: TODO) {
    this.menuItem = item.title;
    this.$router.push({
      name: item.link
    });
  }

  async handleUserActions(item: TODO) {
    this.menuItem = item.title;
    if (item.title === 'Logout') {
      await userModule.logout();
    }
    this.$router.push(item.link);
  }

  mounted() {
  }
}
