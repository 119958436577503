





















































































































































































































import Vue from 'vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { Component, Watch } from 'vue-property-decorator';
import { customerModule } from '@/store/modules/customers';
import { productModule } from '@/store/modules/products';
import { orderModule } from '@/store/modules/orders';
import { appModule } from '@/store/modules/app';

@Component({
  components: {
    ConfirmDialog
  }
})
export default class OrderForm extends Vue {
 
  private modalTitle = 'Add Product';
  private modalText = 'Select the category and product from the list';
  private addProductModal = false;
  private dialog = false;
  private dialogTitle = 'Product Delete Dialog';
  private dialogText = 'Do you want to delete this product?';
  private orderDateMenu = false;
  private shippedDateMenu = false;
  private errors = [];
  private title = '';
  private productId = null;
  private categoryId = 0;
  private color = '';
  private selectedProduct: null;

@Watch('categoryId')
categoryChanged(newId: number, oldId: number){
  if(newId !== oldId){
    orderModule.getProductsByCategory(newId)
  }
}

  get customers() {
    console.log(customerModule.customer);
    return orderModule.customers;
  }

  get order() {
    return orderModule.order;
  }

  get categories() {
    console.log(productModule.categories)
    return productModule.categories;
  }

  get products() {
    return orderModule.products;
  }

  get loading() {
    return appModule.loading;
  }

  get mode() {
    return appModule.mode;
  }

  get snackbar() {
    return appModule.snackbar;
  }

  get notice() {
    return appModule.notice;
  }

  save() {
    const order = { ...this.order };
    delete order.customer;
    console.log(order);
    orderModule.saveOrder(this.order);
  }

  selectCustomer(item) {
    this.order.customerId = item.value;
  }

  getOrderById() {
    orderModule.getOrderById(this.$route.params.id);
  }

  cancel() {
    this.$router.push({ name: 'orders' });
  }

  remove(item) {
    this.selectedProduct = item;
    this.dialog = true;
  }

  onConfirm() {
    orderModule.deleteProduct(this.selectedProduct);
    this.selectedProduct = null;
    this.dialog = false;
  }

  onCancel() {
    this.selectedProduct = null;
    this.dialog = false;
  }

  addProduct() {
    this.addProductModal = true;
  }

  saveProduct() {
    orderModule.addProductToOrder(this.productId);
    this.productId = null;
    this.addProductModal = false;
  }

  cancelAddProduct() {
    this.addProductModal = false;
  }

  getProductsByCategory() {
    orderModule.getProductsByCategory(this.categoryId);
  }

  closeSnackbar() {
    appModule.closeNotice();
  }

  created() {
    // this.getCustomers();
    this.getOrderById();
    orderModule.getCustomers();
    productModule.getCategories();
  }

  mounted() {
    if (this.$route.params.id) {
      this.title = 'Edit Order';
      this.$nextTick(() => {
        // this.shippedDate = this.order.shippedDate;
        // this.orderDate = this.order.orderDate;
      });
    } else this.title = 'New Order';
  }
}
