






















import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class ConfirmDialog extends Vue {
  @Prop() dialogText!: string;
  @Prop() dialogTitle!: string;
  @Prop() dialog!: string;
  mini = false;
  isRootComponent = true;

  drawer = true;
  fixed = false;
  created() {}
  mounted() {}
}
