








































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { productModule } from '@/store/modules/products';
import { appModule } from '@/store/modules/app';

@Component
export default class ProductForm extends Vue {
  errors = [];
  title = '';
  color = '';
  rules = {
    name: [val => (val || '').length > 0 || 'This field is required'],
    category: [val => typeof val === 'number' || 'This field is required']
  };

  save() {
    const product = Object.assign({}, this.product);
    delete product.category;
    productModule.saveProduct(this.product);
  }

  selectCategory(item) {
    this.product.categoryId = item.value;
  }

  getProduct() {
    productModule.getProductById(this.$route.params.id);
  }

  cancel() {
    this.$router.push({ name: 'products' });
  }

  get product() {
    return productModule.product;
  }

  get loading() {
    return productModule.loading;
  }

  get mode() {
    return appModule.mode;
  }

  get snackbar() {
    return appModule.snackbar;
  }

  get notice() {
    return appModule.notice;
  }
  
  get categories() {
    return productModule.categories;
  }

  isValid() {
    return this.product && this.product.categoryId && this.product.productName;
  }

  created() {
    productModule.getCategories();
    this.getProduct();
  }
  
  mounted() {
    if (this.$route.params.id) {
      this.title = 'Edit Product';
    } else this.title = 'New Product';
  }
}
